<template>
  <v-btn
    color="secondary"
    rounded
    v-bind="$attrs"
    v-on="$listeners"
    v-on:click="trackEvent"
  >
    <slot />
  </v-btn>
</template>

<script>
  import firebase from 'firebase/app';

  export default {
    name: 'BaseBtn',
    props: ['trackingName'],
    methods: {
      trackEvent: function () {
        firebase.analytics().logEvent("button_clicked", {
          button_name: this.trackingName
        });
      }
    }
  }
</script>
