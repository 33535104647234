import Vue from 'vue'
import './plugins/base'
import vuetify from './plugins/vuetify'
import App from './App.vue'
import firebase from 'firebase/app'
import 'firebase/analytics'

const firebaseConfig = {
  apiKey: "AIzaSyCCjNZB8R3mq98VFTgahXbs2YN3cWD2WyY",
  authDomain: "alaga-vet.firebaseapp.com",
  databaseURL: "https://alaga-vet.firebaseio.com",
  projectId: "alaga-vet",
  storageBucket: "alaga-vet.appspot.com",
  messagingSenderId: "44331087723",
  appId: "1:44331087723:web:56638cd854a05d9ae37e2e",
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
  measurementId: "G-XXC7E1LRD7"
};
firebase.initializeApp(firebaseConfig);
firebase.analytics();

Vue.config.productionTip = false;

new Vue({
  vuetify,
  render: h => h(App),
}).$mount('#app');
